import logo from './logo.png';
import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" /> {/* Add the logo image */}        <h1>Welcome to TIMECAT ($CHRONO)</h1>
        <p>TIMECAT is the latest meme coin sensation! Join the revolution with $CHRONO.</p>
      </header>
    </div>
  );
}

export default App;
